<template>
<div v-if="$parent.user != null" class="user" style="padding-top: 50px;">

    <vs-dialog v-model="cropDialog" prevent-close class="chooseLayout">
        <template #header>
            <h4 style="margin-bottom: 0;">
                Ritaglia la tua <b>immagine</b>
            </h4>

        </template>

        <vs-row>
            <div class="cropper-wrapper">
                <div v-if="cropImage != null" :style="{backgroundImage: 'url(' + cropImage + ')'}" class="image-background"></div>
                <cropper ref="cropper" v-if="cropImage != null && cropDialog" class="cropper" :stencil-component="$options.components.CircleStencil" :src="cropImage" :stencil-props="{aspectRatio: 1}" />
            </div>

        </vs-row>

        <template #footer>
            <div class="footer-dialog">
                <vs-button block @click="confirmCrop">
                    Conferma modifiche
                </vs-button>
            </div>
        </template>
    </vs-dialog>
    <vs-row justify="center" style="margin-top: 40px;" v-if="user != null">
        <input type="file" ref="imageInput" style="display: none;" accept="image/*" @change="fileInputChange" />
        <!-- PROFILE PIC -->
        <vs-row class="propic-mobile" direction="column" align="center" justify="center">

            <vs-avatar history history-gradient circle v-if="$parent.user != null" size="150" @click="$refs.imageInput.click()" style="cursor: pointer;" :loading="uploading">
                <img :src="baseUrl + '/User/Media'" alt="" v-if="$parent.user.proPicFileId != null" />
                <template #text v-if="$parent.user.proPicFileId == null">
                    {{ $parent.user.invoicingDetails.name }} {{ $parent.user.invoicingDetails.surname != null ? $parent.user.invoicingDetails.surname : ''}}
                </template>
                <template #badge>
                    <i class='bx bx-edit-alt bx-sm'></i>
                </template>
            </vs-avatar>

            <h4 style="font-weight: 100; font-size: 22px;">{{ $parent.user.name }} {{ $parent.user.surname }}</h4>
            <h5 v-if="user.verified"><i class='bx bxs-badge-check bx-sm'></i> Verificato</h5>
        </vs-row>
        <vs-row style="width: 69%; display: grid; grid-template-columns: 30% 70%; grid-column-gap: 25px; grid-auto-rows: 1fr; grid-row-gap: 5px;" justify="space-between" class="user-columns">
            <vs-row direction="column" style="background: white;padding: 20px;border-radius: 21px; height: 100%;" class="sx-column">
                <!-- PROFILE PIC -->
                <vs-row direction="column" align="center" justify="center">

                    <vs-avatar history history-gradient circle v-if="$parent.user != null" size="150" @click="$refs.imageInput.click()" style="cursor: pointer;" :loading="uploading">
                        <img :src="baseUrl + '/User/Media'" alt="" v-if="$parent.user.proPicFileId != null" />
                        <template #text v-if="$parent.user.proPicFileId == null">
                            {{ $parent.user.invoicingDetails.name }} {{ $parent.user.invoicingDetails.surname != null ? $parent.user.invoicingDetails.surname : ''}}
                        </template>
                        <template #badge>
                            <i class='bx bx-edit-alt bx-sm'></i>
                        </template>
                    </vs-avatar>

                    <h4 style="font-weight: 100; font-size: 22px;">{{ $parent.user.name }} {{ $parent.user.surname }}</h4>
                    <h5 v-if="user.verified"><i class='bx bxs-badge-check bx-sm'></i> Verificato</h5>
                </vs-row>
                <vs-row>
                    <vs-button size="large" transparent block icon @click="$router.push('/user/account')" :active="$route.params.tab == 'account'">
                        <i class='bx bxs-user-circle bx-sm'></i> Account
                    </vs-button>
                </vs-row>
                <vs-row>
                    <vs-button size="large" transparent block icon @click="$router.push('/user/orders')" :active="$route.params.tab == 'orders'">
                        <i class='bx bxs-package bx-sm'></i> I miei ordini
                    </vs-button>
                </vs-row>
                <vs-row style="margin-top: auto;" v-if="$route.params.tab == 'account'">
                    <vs-button size="large" block icon @click="saveUser" :loading="loading">
                        <i class='bx bx-check bx-sm'></i> Salva modifiche
                    </vs-button>
                </vs-row>
            </vs-row>
            <vs-row v-if="$route.params.tab == 'account'" direction="column" style="background: white;padding: 20px;border-radius: 21px;">
                <vs-row>
                    <h2>Dati Anagrafici</h2>
                </vs-row>
                <vs-row style="padding-top: 40px;">
                    <vs-col w="6" xs="12" style="padding-right: 10px;">
                        <vs-input type="text" label="Nome" v-model="user.invoicingDetails.name" block />
                    </vs-col>
                    <vs-col w="6" xs="12" style="padding-left: 10px;">
                        <vs-input type="text" label="Cognome" v-model="user.invoicingDetails.surname" block />
                    </vs-col>
                </vs-row>
                <vs-row style="padding-top: 40px;">
                    <vs-col w="6" xs="12" style="padding-right: 10px;">
                        <vs-input type="text" label="PIVA" v-model="user.invoicingDetails.vat" block />
                    </vs-col>
                    <vs-col w="6" xs="12" style="padding-left: 10px;">
                        <vs-input type="text" label="Codice Fiscale" v-model="user.invoicingDetails.cf" block />
                    </vs-col>
                </vs-row>
                <vs-row style="padding-top: 40px;">
                    <vs-col w="6" xs="12" style="padding-right: 10px;">
                        <vs-input type="text" label="Ragione sociale" v-model="user.invoicingDetails.businessName" block />
                    </vs-col>
                    <vs-col w="6" xs="12" style="padding-left: 10px;">
                        <vs-input type="text" label="Codice univoco" v-model="user.invoicingDetails.uniqueCode" block />
                    </vs-col>
                </vs-row>
                <vs-row style="padding-top: 20px;">
                    <h2>Informazioni di contatto</h2>
                </vs-row>
                <vs-row style="padding-top: 40px;">
                    <vs-col w="6" xs="12" style="padding-right: 10px;">
                        <vs-input type="text" label="Cellulare" v-model="user.invoicingDetails.mobile" block />
                    </vs-col>
                    <vs-col w="6" xs="12" style="padding-left: 10px;">
                        <vs-input type="text" label="PEC" v-model="user.invoicingDetails.pec" block />
                    </vs-col>
                </vs-row>
                <vs-row style="padding-top: 20px;">
                    <h2>Indirizzo di fatturazione</h2>
                </vs-row>
                <vs-row style="padding-top: 40px;">
                    <vs-col w="6" xs="12" style="padding-right: 10px;">
                        <vs-input type="text" label="Indirizzo" v-model="user.invoicingDetails.address" block />
                    </vs-col>
                    <vs-col w="1" xs="6" style="padding-left: 10px; padding-right: 10px;">
                        <vs-input type="text" label="No." v-model="user.invoicingDetails.addressNo" block />
                    </vs-col>
                    
                    <vs-col w="1" xs="6" style="padding-left: 10px;">
                        <vs-input type="text" label="CAP" v-model="user.invoicingDetails.postalCode" block />
                    </vs-col>

                    <vs-col w="2" xs="6" style="padding-left: 10px; padding-right: 10px;">
                        <vs-input type="text" label="Città" v-model="user.invoicingDetails.city" block />
                    </vs-col>
                    <vs-col w="2" xs="6" style="padding-left: 10px; padding-right: 10px;">
                        <vs-input type="text" label="Paese" v-model="user.invoicingDetails.country" block />
                    </vs-col>
                </vs-row>
            </vs-row>
            <vs-row v-if="$route.params.tab == 'orders'" direction="column" style="background: white;padding: 20px;border-radius: 21px; gap: 20px;">
                <vs-row>
                    <h2>I miei ordini</h2>
                </vs-row>
                <vs-row justify="space-between" align="center" v-for="o in userOrders" :key="'order-' + o.id">
                    <vs-row align="flex-start" justify="flex-start" style="width: 70%;" class="order-info">
                        <img class="product-preview" :src="baseUrl + '/Product/Media/' + o.product.fileId" style="border-radius: 15px; height: 150px; margin-right: 20px;" />
                        <vs-row direction="column" style="width: 60%;">
                            <p><b>N° Ordine:</b> {{ o.id }}</p>
                            <p><b>Stato:</b>
                                <template v-if="o.invoices[0] == null">
                                    Bozza
                                </template>
                                <template v-else-if="o.invoices[0].status == 'PENDING'">
                                    In attesa di pagamento
                                </template>
                                <template v-else-if="o.invoices[0].status == 'EXPIRED'">
                                    Mancato pagamento
                                </template>
                                <template v-else-if="o.invoices[0].status == 'PAID'">
                                    <template v-if="o.isReady">
                                        Elaborazione file completata
                                    </template>
                                    <template v-else>
                                        Elaborazione file in corso.
                                    </template>
                                </template>
                            </p>
                            <p><b>Riepilogo:</b> {{ o.qty }} x {{ o.product.title }}</p>
                            <p><b>Totale:</b> {{o.invoices[0] != null ? o.invoices[0].amount.toFixed(2) + '€' : '0.00€'}}</p>
                            <p><b>Data:</b> {{ (new Date(o.invoices[0] != null ? o.invoices[0].createdAt : o.createdAt)).toLocaleDateString() }} {{ (new Date(o.invoices[0] != null ? o.invoices[0].createdAt : o.createdAt)).toLocaleTimeString() }}</p>

                        </vs-row>
                    </vs-row>
                    <vs-row direction="column" style="width: 30%;" class="order-buttons">
                        <vs-row>
                            <vs-button size="large" transparent block icon :disabled="(o.invoices[0].status != 'PENDING' || o.invoices[0].stripeInvoiceUrl == null)" @click="openOrderInvoice(o)" v-if="o.invoices[0]">
                                <i class='bx bxs-credit-card bx-sm'></i>

                                <template v-if="o.invoices[0].status == 'PENDING'">
                                    <template v-if="o.invoices[0].stripeInvoiceUrl != null">
                                        Paga ora
                                    </template>
                                    <template v-else>
                                        Pagamento non disp.
                                    </template>
                                </template>

                                <template v-else-if="o.invoices[0].status == 'EXPIRED'">
                                    Scaduto
                                </template>
                                <template v-else-if="o.invoices[0].status == 'PAID'">
                                    Pagato
                                </template>
                            </vs-button>
                            <vs-button v-else size="large" transparent block icon @click="openDraft(o)">
                                <i class='bx bxs-layout bx-sm'></i> Vai alla bozza
                            </vs-button>
                            <vs-button @click="downloadOrderFile(o)" size="large" block icon :disabled="!o.isReady">
                                <i class='bx bx-download bx-sm'></i> Download
                            </vs-button>
                            <vs-button @click="$router.push('/preview/' + o.id)" size="large" block icon :disabled="!o.isReady">
                                <i class='bx bx-book-reader bx-sm'></i> Visualizza
                            </vs-button>
                        </vs-row>
                    </vs-row>
                </vs-row>
            </vs-row>

        </vs-row>

    </vs-row>
</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
    Cropper,
    CircleStencil
} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import {
    apiCall,
    baseUrl
} from '../client';
export default {
    props: {
        orders: Boolean // if this field is true while router navigation, the order tab will be automatically selected
    },
    components: {
        Cropper,
        CircleStencil
    },
    data() {
        return {
            user: null,
            loading: false,
            cropImage: null,
            cropDialog: false,
            baseUrl,
            ts: 0,
            uploading: false,
            userOrders: [],
            
            currentPage: 0,
            orderFinished: false,
            orderLoading: false,

        }
    },
    mounted() {
        this.user = JSON.parse(JSON.stringify(this.$parent.user));
        this.getOrders();

        var vm = this;

        window.onscroll = () => {
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;

            if (bottomOfWindow) {
                if (!vm.orderFinished && !vm.orderLoading) { // non ho finito le pagine e non sta caricando
                    vm.currentPage++;
                }
            }
        };
    },

    watch: {
        '$parent.user'(n) {
            this.user = JSON.parse(JSON.stringify(n));
        },

        currentPage() {
            this.getOrders();
        }
    },

    methods: {
        openDraft(o) {
            this.$router.push('/store/' + o.product.id + '/' + o.id);
        },

        downloadOrderFile(o) {
            window.open(baseUrl + '/Order/' + o.id + '/Download', "_blank");
        },

        openOrderInvoice(o) {
            if (o.invoices[0].stripeInvoiceUrl == null) {
                return;
            }
            window.open(o.invoices[0].stripeInvoiceUrl, "_blank");
        },

        async fileInputChange() {
            var vm = this;
            const reader = new FileReader();
            const file = this.$refs.imageInput.files[0];
            console.log(vm.$refs)
            reader.addEventListener("load", async () => {
                vm.cropImage = reader.result;
                vm.cropDialog = true;
            }, false);
            if (file) {
                reader.readAsDataURL(file);
            }
        },

        async confirmCrop() {
            var vm = this;
            vm.cropDialog = false;
            const {
                canvas
            } = this.$refs.cropper.getResult();
            if (canvas) {
                var b64 = canvas.toDataURL();
                vm.uploading = true;
                var result = await apiCall('PUT', '/User/Media', {
                    Content: b64.split('base64,')[1]
                });
                vm.uploading = false;
                if (result.status == 200) {
                    this.$vs.notification({
                        icon: '<i class="bx bx-check"></i>',
                        color: 'success',
                        position: 'top-right',
                        title: 'Fatto!',
                        text: `Tutte le modifiche sono state salvate.`
                    })
                    vm.ts = Date.now() // force propic refresh
                } else {
                    this.$vs.notification({
                        icon: '<i class="bx bx-error"></i>',
                        color: 'danger',
                        position: 'top-right',
                        title: 'Ops!',
                        text: `Impossibile salvare le modifiche, dimensione massima 6MB.`
                    })
                }
            }
        },

        async saveUser() {
            this.loading = true;
            var result = await apiCall('PATCH', '/User', this.user.invoicingDetails);
            this.loading = false;
            if (result.status == 200) {
                this.$vs.notification({
                    icon: '<i class="bx bx-check"></i>',
                    color: 'success',
                    position: 'top-right',
                    title: 'Fatto!',
                    text: `Tutte le modifiche sono state salvate.`
                })
                this.$parent.user.invoicingDetails = result.data;
                this.$parent.user.__ob__.dep.notify();
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile salvare le modifiche.`
                })
            }
        },

        async getOrders() {
            this.orderLoading = true;
            var result = await apiCall('GET', '/Order', {
                page: this.currentPage,
            });
            if (result.status == 200) {
                if (result.data.length > 0) {
                    this.orderFinished = false;
                } else {
                    this.orderFinished = true;
                }
                this.userOrders = this.userOrders.concat(result.data);
            } else {
                this.$vs.notification({
                    icon: '<i class="bx bx-error"></i>',
                    color: 'danger',
                    position: 'top-right',
                    title: 'Ops!',
                    text: `Impossibile ottenere l'elenco degli ordini.`
                })
            }
            this.orderLoading = false;
        },
    }
}
</script>

<style>
.user .vs-avatar__badge {
    width: 32px;
    height: 32px;
    border-radius: 10px;
}

.user .vs-avatar {
    font-size: 4rem;
}

.propic-mobile {
    display: none;
}

/* MOBILE */
@media (max-width: 1023px) {
    .user .user-columns {
        grid-template-columns: 100% !important;
        width: 85% !important;
    }

    .user {
        padding-top: 0 !important;
    }

    .user .sx-column {
        display: none;
    }

    .user .vs-col {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-top: 32px;
    }

    .propic-mobile {
        display: flex;
        margin-bottom: 20px;
    }

    img.product-preview {
        width: 100% !important;
        height: auto !important;
    }

    .order-info,
    .order-buttons {
        width: 100% !important;
    }
}
</style>
